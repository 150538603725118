import React, { useContext, useEffect, useState } from "react";
import ApplicationContext from "../../../context/ApplicationContext";
import { IProduct } from "../../../types/IProduct";
import { useLocation, useNavigate } from "react-router-dom";
import {
  deleteOption,
  deleteProduct,
  getSettings,
} from "../../../services/Service";

import TreeMenu, { ItemComponent } from "react-simple-tree-menu";
import "../../../../node_modules/react-simple-tree-menu/dist/main.css";

import {
  Button,
  Container,
  Modal,
  Nav,
  NavDropdown,
  Navbar,
} from "react-bootstrap";
import { Add as AddProduct } from "./controls/product/Add";
import { GenerateGUID } from "../../../helpers/Helpers";
import { IOption } from "../../../types/IOption";
import { IVariation } from "../../../types/IVariation";
import { IExtra } from "../../../types/IExtra";

export interface IAllProductsProps {
  editId(id: number): void;
}

export interface INode {
  key: string;
  label: string;
  url: string;
  nodes: INode[];
  product: IProduct | undefined;
}

export const ProductTree: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [showConfirm, setShowConfirm] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [treeNodes, setTreeNode] = useState<INode[]>([]);
  const [refresh, setRefresh] = useState("");
  const [selectedId, setSelectedId] = useState("");

  const refreshValue = context.Data.getRefreshUuId();

  const [showAddProduct, setShowAddProduct] = useState(false);

  async function getBranchSettings() {
    const response = await getSettings();

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    context.BranchMenu.setMenu(response.menu);

    const p = context.BranchMenu.getMenu().products.sort((a, b) =>
      a.position > b.position ? 1 : -1
    );

    setProducts(p);
    setRefresh(GenerateGUID);
  }

  function fillExtras(nodes: INode[], id: number) {
    const data: IExtra[] = context.BranchMenu.getMenu().extras.filter(
      (d) => d.optionId === id
    );
    if (data.length === 0) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      let n: INode = {
        key: data[i].uuid.toString(),
        label:
          "e: " + data[i].description + "(" + data[i].price.toFixed(2) + ")",
        url: "",
        nodes: [],
        product: undefined,
      };
      nodes.push(n);
    }
  }
  function fillVariations(nodes: INode[], id: number) {
    const data: IVariation[] = context.BranchMenu.getMenu().variations.filter(
      (d) => d.optionId === id
    );
    if (data.length === 0) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      let n: INode = {
        key: data[i].uuid,
        label:
          "v: " + data[i].description + "(" + data[i].price.toFixed(2) + ")",
        url: "",
        nodes: [],
        product: undefined,
      };
      nodes.push(n);
    }
  }

  function fillOptions(nodes: INode[], id: number) {
    const data: IOption[] = context.BranchMenu.getMenu().options.filter(
      (d) => d.productId === id
    );
    if (data.length === 0) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      let n: INode = {
        key: data[i].uuid,
        label:
          "o: " + data[i].description + "(" + data[i].price.toFixed(2) + ")",
        url: "",
        nodes: [],
        product: undefined,
      };
      fillVariations(n.nodes, data[i].id);
      fillExtras(n.nodes, data[i].id);
      nodes.push(n);
    }
  }

  function fillInstructions(nodes: INode[], id: number) {
    const data = context.BranchMenu.getMenu().instructions.filter(
      (d) => d.productId === id
    );

    if (data.length === 0) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      let n: INode = {
        key: data[i].uuid,
        label:
          "instruction: " +
          data[i].description +
          "(" +
          data[i].price.toFixed(2) +
          ")",
        url: "",
        nodes: [],
        product: undefined,
      };
      nodes.push(n);
    }
  }
  function fillChild(nodes: INode[], parentId: number) {
    const data = products.filter((d) => d.parentId === parentId);
    if (data.length === 0) {
      return;
    }

    for (let i = 0; i < data.length; i++) {
      let n: INode = {
        key: data[i].uuid,
        label: data[i].description + "(" + data[i].price.toFixed(2) + ")",
        url: "",
        nodes: [],
        product: data[i],
      };

      nodes.push(n);

      fillInstructions(n.nodes, data[i].id);
      fillOptions(n.nodes, data[i].id);

      fillChild(n.nodes, data[i].id);
    }
  }

  function buildTreeData() {
    let bNodes: INode[] = [];
    for (let i = 0; i < products.length; i++) {
      if (products[i].parentId === 1) {
        let node: INode = {
          key: products[i].uuid,
          label: products[i].description,
          url: "",
          product: products[i],
          nodes: [],
        };

        fillInstructions(node.nodes, node.product ? node.product.id : -1);
        fillOptions(node.nodes, node.product ? node.product.id : -1);

        fillChild(node.nodes, node.product ? node.product.id : -1);
        bNodes.push(node);
      }
    }
    setTreeNode(bNodes);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  useEffect(() => {
    buildTreeData();
  }, [products]);

  useEffect(() => {
    getBranchSettings();
  }, [refreshValue]);

  // const confirmView = (): JSX.Element => {
  //   return (
  //     <Modal
  //       show={showConfirm}
  //       onHide={() => setShowConfirm(false)}
  //       //style={{ width: "800px" }}
  //     >
  //       <Modal.Header closeButton>
  //         <Modal.Title>Delete</Modal.Title>
  //       </Modal.Header>
  //       <Modal.Body>
  //         <>
  //           <h5>Are you sure?</h5>
  //         </>
  //       </Modal.Body>
  //       <Modal.Footer className="modal-footer justify-content-between">
  //         <div className="mr-auto"></div>
  //         <div>
  //           <Button
  //             variant="primary"
  //             size="lg"
  //             onClick={() => setShowConfirm(false)}
  //           >
  //             Cancel
  //           </Button>{" "}
  //           <Button
  //             variant="danger"
  //             size="lg"
  //             onClick={() => removeInstruction()}
  //           >
  //             Delete
  //           </Button>
  //         </div>
  //       </Modal.Footer>
  //     </Modal>
  //   );
  // };

  const viewAddProduct = (): JSX.Element => {
    return (
      <Modal
        show={showAddProduct}
        onHide={() => setShowAddProduct(false)}
        //style={{ width: "800px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <AddProduct />
          </>
        </Modal.Body>
        <Modal.Footer className="modal-footer justify-content-between">
          <div className="mr-auto"></div>
          <div>
            <Button
              variant="primary"
              size="lg"
              onClick={() => setShowAddProduct(false)}
            >
              Cancel
            </Button>{" "}
            <Button
              variant="danger"
              size="lg"
              //onClick={() => removeInstruction()}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  function findProductById(uuid: string) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.uuid === uuid;
    });
  }

  function findOptionById(uuid: string) {
    return context.BranchMenu.getMenu().options.find((element) => {
      return element.uuid === uuid;
    });
  }

  function addProduct() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const product = findProductById(id);

    if (product === undefined) {
      return;
    }

    navigate("/AddProduct/" + product.id + "/defaultPId/TreeView");
  }

  function editProduct() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const product = findProductById(id);

    if (product === undefined) {
      return;
    }
    navigate("/AddProduct/" + product.id + "/edit/TreeView");
  }

  async function removeProduct() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const product = findProductById(id);

    if (product === undefined) {
      return;
    }

    const prd: IProduct = {
      id: product.id,
      uuid: "",
      parentId: 0,
      description: "",
      imageUrl: "",
      position: 0,
      isCategory: false,
      price: 0,
      qty: 0,
    };

    const response = await deleteProduct(prd);

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    } else {
      sleep(3000);
      getBranchSettings();
    }
  }

  async function removeOption() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const option = findOptionById(id);

    if (option === undefined) {
      return;
    }

    const op: IOption = {
      id: option.id,
      uuid: "",
      description: "",
      position: 0,
      price: 0,
      qty: 0,
      productId: 0,
      image: "",
    };

    const response = await deleteOption(op);

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    } else {
      sleep(3000);
      getBranchSettings();
    }
  }

  function copyOverInstructions() {
    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const product = findProductById(id);

    if (product === undefined) {
      return;
    }
    navigate("/CopyInstructions/" + product.id + "/TreeView");
  }

  function copyInstruction() {
    //TODO: copy instruction to another product
    //target product and instruction

    const selectedTreeIds = selectedId.split("/");
    const id = selectedTreeIds[selectedTreeIds.length - 1];
    const instruction = findInstructionById(id);

    if (instruction === undefined) {
      return;
    }
    navigate("/CopyInstruction/" + instruction.id + "/TreeView");
  }

  function findInstructionById(uuid: string) {
    return context.BranchMenu.getMenu().instructions.find((element) => {
      return element.uuid === uuid;
    });
  }

  const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

  const menu = (): JSX.Element => (
    <div>
      <Navbar className="bg-body-tertiary">
        <Container>
          {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand> */}
          {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="Product" id="basic-nav-dropdown">
                <NavDropdown.Item onClick={() => addProduct()}>
                  Add
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => editProduct()}>
                  Edit
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => removeProduct()}>
                  Delete
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => copyOverInstructions()}>
                  Copy instructions over
                </NavDropdown.Item>
                <NavDropdown.Item href="#action/3.4">Copy</NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Instructions" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Add</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Edit</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Delete</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => copyInstruction()}>
                  Copy
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Options" id="basic-nav-dropdown">
                <NavDropdown.Item href="#action/3.1">Add</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.2">Edit</NavDropdown.Item>
                <NavDropdown.Item onClick={() => removeOption()}>
                  Delete
                </NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">Copy</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <br />
    </div>
  );

  const productsTree = (): JSX.Element => (
    <>
      <h1>Products</h1>
      <hr />
      <div>{menu()}</div>
      <div>
        <TreeMenu
          data={treeNodes}
          resetOpenNodesOnDataUpdate
          initialOpenNodes={undefined}
          onClickItem={({ key, id, label, ...props }) => {
            setSelectedId(key);
          }}
        >
          {({ items }) => (
            <ul className="tree-item-group">
              {items.map(({ key, onClickItem, ...props }) => (
                <ItemComponent key={key} {...props} />
              ))}
            </ul>
          )}
        </TreeMenu>
      </div>
    </>
  );

  return (
    <>
      <>{productsTree()}</>
      <>{viewAddProduct()}</>
    </>
  );
};
