import { IBranchSettings } from "../../types/IBranchSettings";
import { IOrganisation } from "../../types/IOrganisation";
import { IPrinter } from "../../types/IPrinter";
import { IConfiguration } from "./IConfiguration";

const o: IOrganisation = {
  id: 0,
  name: "",
  addressLines: [],
  telephone: "",
  endComment: "",
  website: "",
  logo: "",
};

const p: IPrinter = {
  env: "windows",
  defaultUrl: "https://print.aleaf.co.uk/Default.aspx?app=epos&receiptId=",
};

let branchSettings: IBranchSettings = {
  organisation: o,
  printer: p,
};

let reloadData = true;

export class Configuration implements IConfiguration {
  public setSettings(s: IBranchSettings): void {
    branchSettings = s;
  }
  public getSettings(): IBranchSettings {
    return branchSettings;
  }

  public setInvalidate(value: boolean): void {
    reloadData = value;
  }
  public invalidate(): boolean {
    return reloadData;
  }
}
