import { IUser } from "../../types/IUser";
import { IAuthenticatedUser } from "./IAuthenticatedUser";

let user: IUser = {
  branchId: 0,
  displayName: "bobby",
  userId: 0,
  AuthId: "aVeryLongGuid",
};

export class AuthenticatedUser implements IAuthenticatedUser {
  public setUser(u: IUser): void {
    user = u;
  }
  public getUser(): IUser {
    return user;
  }
}

//sessionStorage.setItem("User", JSON.stringify(u));

// const newUser: IUser = {
//   success: false,
//   information: "",
//   sessionGuid: "",
//   awaitingApproval: false,
//   familyGuid: "",
//   branchId: 0,
//   displayName: "",
//   userId: 0,
// };
// return JSON.parse(
//   sessionStorage.getItem("User") || JSON.stringify(newUser)
// );
//}
