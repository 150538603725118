import axios, { AxiosResponse } from "axios";
import { IItem, IReturnItem } from "../types/IItem";
import { ISearch } from "../types/ISearch";
import { ISettings } from "../types/ISettings";
import { IMenu } from "../types/IMenu";
import { IReceipt } from "../types/IReceipt";
import { IReceiptData } from "../types/IReceiptData";
import { IProduct } from "../types/IProduct";
import { IExtra } from "../types/IExtra";
import { IVariation } from "../types/IVariation";
import { IInstruction } from "../types/IInstruction";
import { IOption } from "../types/IOption";
import { INewProduct } from "../types/INewProduct";
import { INewOption } from "../types/INewOption";
import { IInstructionCopier } from "../types/IInstruIctionCopier";

export const API = axios.create({
  baseURL: "https://eposapi.aleaf.co.uk/",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
  },
  validateStatus: () => true,
});

export const getSettings = (): Promise<ISettings | void> => {
  return API.get("Settings")
    .then((response: AxiosResponse<ISettings>) => {
      return response.data;
    })
    .catch(handleError);
};

export const createOrder = (data: IReceiptData): Promise<number | void> => {
  return API.post("Receipt/Add", data)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

// product
export const storeProduct = (product: IProduct): Promise<boolean | void> => {
  return API.post("Product/Add", product)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};
export const deleteProduct = (product: IProduct): Promise<boolean | void> => {
  return API.post("Product/Delete", product)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

// extras
export const storeExtra = (extra: IExtra): Promise<number | void> => {
  return API.post("Extra/Add", extra)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

// Variation
export const storeVariation = (data: IVariation): Promise<number | void> => {
  return API.post("Variation/Add", data)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

export const deleteVariation = (data: IVariation): Promise<number | void> => {
  return API.post("Variation/Delete", data)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

// Instruction
export const storeInstruction = (
  data: IInstruction
): Promise<number | void> => {
  return API.post("Instruction/Add", data)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};
export const deleteInstruction = (
  data: IInstruction
): Promise<boolean | void> => {
  return API.post("Instruction/Delete", data)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

export const copyProductInstruction = (
  data: IInstructionCopier
): Promise<boolean | void> => {
  return API.post("Instruction/CopyInstructions", data)
    .then((response: AxiosResponse<boolean>) => {
      return response.data;
    })
    .catch(handleError);
};

// Options
export const storeOption = (data: INewOption): Promise<number | void> => {
  return API.post("Option/Add", data)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};
export const deleteOption = (op: IOption): Promise<number | void> => {
  return API.post("Option/Delete", op)
    .then((response: AxiosResponse<number>) => {
      return response.data;
    })
    .catch(handleError);
};

function handleError(error: any) {
  console.log("api error @ " + new Date());
  console.log(error);
  console.log(error.response);

  if (error.response) {
    console.log(error.response.data);
    console.log(error.response.status);
    console.log(error.response.headers);
  } else if (error.request) {
    console.log(error.request);
  } else {
    console.log("Error", error.message);
  }
}

// export const addProduct = (
//   product: IProductNew
// ): Promise<IProduct[] | void> => {
//   return API.post("Products/Add", product)
//     .then((response: AxiosResponse<IProduct[]>) => {
//       return response.data;
//     })
//     .catch(handleError);
// };

// export const updateProduct = (
//   product: IProductNew
// ): Promise<boolean | void> => {
//   return API.post("Products/Update", product)
//     .then((response: AxiosResponse<boolean>) => {
//       return response.data;
//     })
//     .catch(handleError);
// };

// export const deleteProduct = (id: number): Promise<boolean | void> => {
//   return API.post("Products/Delete", id)
//     .then((response: AxiosResponse<boolean>) => {
//       return response.data;
//     })
//     .catch(handleError);
// };

// export const getProducts = (branchId: number): Promise<IProduct[] | void> => {
//   return API.post("Products/Products", branchId)
//     .then((response: AxiosResponse<IProduct[]>) => {
//       return response.data;
//     })
//     .catch(handleError);
// };

// export const addReceipt = (receipt: IReceipt): Promise<number | void> => {
//   return API.post("Receipt/Add", receipt)
//     .then((response: AxiosResponse<number>) => {
//       return response.data;
//     })
//     .catch(handleError);
// };

// export const getReceipts = (): Promise<IReceipt[] | void> => {
//   return API.get("Receipt")
//     .then((response: AxiosResponse<IReceipt[]>) => {
//       return response.data;
//     })
//     .catch(handleError);
// };

// export const returnReceiptItem = (
//   receiptItem: IReturnItem
// ): Promise<boolean | void> => {
//   return API.post("Receipt/ReturnItem", receiptItem)
//     .then((response: AxiosResponse<boolean>) => {
//       if (response.status === 200) {
//         return response.data;
//       } else {
//         return false;
//       }
//     })
//     .catch(handleError);
// };

// export const dateRangeReceipts = (
//   search: ISearch
// ): Promise<IReceipt[] | void> => {
//   return API.post("Receipt/DateRangeReceipts", search)
//     .then((response: AxiosResponse<IReceipt[]>) => {
//       return response.data;
//     })
//     .catch(handleError);
// };

// export const apiGetSettings = (branchId: number): Promise<ISettings | void> => {
//   return API.post("Settings/Settings", branchId)
//     .then((response: AxiosResponse<ISettings>) => {
//       return response.data;
//     })
//     .catch(handleError);
// };

// export const apiSetSettings = (
//   settings: ISettings
// ): Promise<ISettings | void> => {
//   return API.post("Settings/SaveSettings", settings)
//     .then((response: AxiosResponse<ISettings>) => {
//       return response.data;
//     })
//     .catch(handleError);
// };
