import React, { useContext, useEffect, useState } from "react";
import { Login } from "../login";
import ApplicationContext from "../../context/ApplicationContext";
import { ViewPage } from "../../types/ViewPage";
import { IManagerProps } from "./IManagerProps";

import { getSettings } from "../../services/Service";
import loadingImage from "../../images/loading.gif";
import { IInstruction } from "../../types/IInstruction";
import { MenuItem, Menu, MenuHeader, MenuMenu } from "semantic-ui-react";

import { ITableData } from "../../types/ITableData";
import "./style.css";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { AllProducts } from "../pages/products/AllProducts";
import { AddProduct } from "../pages/products/AddProduct";
import { AddExtra, Extras } from "../pages/extras";
import { AddInstructions, Instructions } from "../pages/Instructions";
import { AddVariation, Variations } from "../pages/variation";
import { AddOption, Options } from "../pages/Options";
import { MultiInstructions } from "../pages/Instructions/MultiInstructions";
import { ProductTree } from "../pages/productTree/ProductTree";
import { CopyInstructions } from "../pages/Instructions/CopyInstructions";

export const Manager: React.FC<IManagerProps> = (
  props: IManagerProps
): JSX.Element => {
  const defaultInstructions: IInstruction[] = [];

  const context = useContext(ApplicationContext);
  const [loginView, setLoginView] = useState(true);
  const [loading, setLoading] = React.useState(false);
  const [loadingError, setLoadingError] = React.useState(false);

  const [newProduct, setNewProduct] = React.useState(false);
  const [viewProducts, setViewProducts] = React.useState(false);
  const [viewExtras, setViewExtras] = React.useState(false);
  const [viewAddExtras, setViewAddExtras] = React.useState(false);
  const [viewVariations, setViewVariations] = React.useState(false);
  const [viewAddVariation, setViewAddVariation] = React.useState(false);

  const [viewInstructions, setViewInstructions] = React.useState(false);
  const [viewAddInstructions, setViewAddInstructions] = React.useState(false);

  const [viewOptions, setViewOptions] = React.useState(false);
  const [viewAddOptions, setViewAddOptions] = React.useState(false);

  const navigate = useNavigate();
  const defaultTableData: ITableData[] = [];

  const handleLoginSuccess = () => {
    setLoginView(false);
    context.ViewPage.setView(ViewPage.home);
    setViewProducts(true);
  };

  const handleRegister = () => {};
  const handleForgotPassword = () => {};

  async function getBranchSettings() {
    setLoading(true);

    const response = await getSettings();

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    console.log(response);

    context.BranchMenu.setMenu(response.menu);
    context.Configuration.setSettings(response.branchSettings);

    //setProducts(context.BranchMenu.getMenu().products);

    setLoading(false);
  }

  useEffect(() => {
    setLoadingError(true);
  }, [context.ApplicationError.hasError()]);

  useEffect(() => {
    getBranchSettings();
  }, []);

  useEffect(() => {
    getBranchSettings();
  }, [context.Configuration.invalidate()]);

  function showProducts() {
    navigate("/Products");
  }

  function showAddProducts() {
    navigate("/AddProduct");
  }

  function showAllExtras() {
    navigate("/Extras");
  }

  function showAddExtras() {
    navigate("/AddExtra");
  }

  function showVariations() {
    navigate("/Variations");
  }
  function showAddVariations() {
    navigate("/AddVariation");
  }

  function showAddInstructions() {
    navigate("/AddInstruction");
  }

  function showInstructions() {
    navigate("/Instructions");
  }

  function showOptions() {
    navigate("/Options");
  }

  function showAddOptions() {
    navigate("/AddOption");
  }

  function showMultiInstructions() {
    navigate("/MultiInstruction");
  }

  function showProductTreeView() {
    navigate("/TreeView");
  }

  function showCopyInstructions() {
    navigate("/CopyInstructions");
  }

  const template = (): JSX.Element => (
    <>
      <div className="container">
        <br />
        <br />
        <div className="row">
          <div style={{ width: "30%" }}>
            <Menu vertical size="small">
              <MenuItem>
                <MenuHeader>Products</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="All products"
                    active={viewProducts}
                    onClick={() => showProducts()}
                  ></MenuItem>
                  <MenuItem
                    name="New"
                    active={newProduct}
                    onClick={() => showAddProducts()}
                  ></MenuItem>
                </MenuMenu>
              </MenuItem>

              <MenuItem>
                <MenuHeader>Extras</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="All extras"
                    active={viewExtras}
                    onClick={showAllExtras}
                  />
                  <MenuItem
                    name="Add"
                    active={viewAddExtras}
                    onClick={showAddExtras}
                  />
                </MenuMenu>
              </MenuItem>
              <MenuItem>
                <MenuHeader>Variation</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="All Variations"
                    active={viewVariations}
                    onClick={showVariations}
                  />
                  <MenuItem
                    name="Add"
                    active={viewAddVariation}
                    onClick={showAddVariations}
                  />
                </MenuMenu>
              </MenuItem>

              <MenuItem>
                <MenuHeader>Instructions</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="All Instructions"
                    active={viewInstructions}
                    onClick={showInstructions}
                  />
                  <MenuItem
                    name="Add"
                    active={viewAddInstructions}
                    onClick={showAddInstructions}
                  ></MenuItem>
                  <MenuItem
                    name="Multi"
                    active={viewAddInstructions}
                    onClick={showMultiInstructions}
                  />
                  <MenuItem
                    name="Copy"
                    active={viewAddInstructions}
                    onClick={showCopyInstructions}
                  />
                </MenuMenu>
              </MenuItem>
              <MenuItem>
                <MenuHeader>Options</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="All Options"
                    active={viewOptions}
                    onClick={showOptions}
                  />
                  <MenuItem
                    name=""
                    active={viewAddOptions}
                    onClick={showAddOptions}
                  >
                    Add
                  </MenuItem>
                </MenuMenu>
              </MenuItem>

              <MenuItem>
                <MenuHeader>Tree View</MenuHeader>

                <MenuMenu>
                  <MenuItem
                    name="Hierarchy"
                    active={viewOptions}
                    onClick={showProductTreeView}
                  />
                </MenuMenu>
              </MenuItem>
            </Menu>
          </div>
          <div style={{ width: "70%" }}> {pages()}</div>
        </div>
      </div>
    </>
  );

  const pages = (): JSX.Element => (
    <>
      <Routes>
        <Route path="/Products" element={<AllProducts />} />
        <Route path="/AddProduct" element={<AddProduct />} />
        <Route path="/AddProduct/:id" element={<AddProduct />} />
        <Route path="/AddProduct/:id/:type/:nav" element={<AddProduct />} />

        <Route path="/Instructions" element={<Instructions />} />
        <Route path="/AddInstruction" element={<AddInstructions />} />
        <Route path="/MultiInstruction" element={<MultiInstructions />} />
        <Route path="/AddInstruction/:id" element={<AddInstructions />} />
        <Route path="/CopyInstructions" element={<CopyInstructions />} />
        <Route
          path="/CopyInstructions/:id/:nav"
          element={<CopyInstructions />}
        />

        <Route path="/Options" element={<Options />} />
        <Route path="/AddOption" element={<AddOption />} />
        <Route path="/AddOption/:id" element={<AddOption />} />

        <Route path="/Extras" element={<Extras />} />
        <Route path="/AddExtra" element={<AddExtra />} />
        <Route path="/AddExtra/:id" element={<AddExtra />} />

        <Route path="/Variations" element={<Variations />} />
        <Route path="/AddVariation" element={<AddVariation />} />
        <Route path="/AddVariation/:id" element={<AddVariation />} />

        <Route path="/TreeView" element={<ProductTree />} />
      </Routes>
    </>
  );

  const manager = (): JSX.Element => (
    <>
      {loginView ? (
        <Login
          successful={handleLoginSuccess}
          register={handleRegister}
          forgotPassword={handleForgotPassword}
        />
      ) : (
        <></>
      )}
      {loginView === false ? <>{template()}</> : <></>}
    </>
  );

  const loadingData = (): JSX.Element => {
    return (
      <>
        {loadingError ? (
          <div className="loading-center">
            {/* <img src={loadingImage} alt="Loading" /> */}
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            Error in communication with service
          </div>
        ) : (
          <div className="loading-center">
            <img src={loadingImage} alt="Loading" />
          </div>
        )}
      </>
    );
  };

  return <div>{loading ? loadingData() : <>{manager()}</>}</div>;
};
