import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import {
  Dropdown,
  Icon,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import ApplicationContext from "../../../context/ApplicationContext";
import { getSettings } from "../../../services/Service";
import { IExtra } from "../../../types/IExtra";
import { ISearchData } from "../../../types/ISearchData";

export interface IAllProductsProps {}

export const Extras: React.FC<IAllProductsProps> = (
  props: IAllProductsProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [searchData, setSearchData] = useState<ISearchData[]>([]);
  const [searchValue, setSearchValue] = useState("Home");

  //selected values
  const [productId, setProductId] = useState(1);
  const [description, setDescription] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [position, setPosition] = useState("");
  const [price, setPrice] = useState("");
  const [qty, setQty] = useState("");
  const [isCategory, setIsCategory] = useState(false);

  //validation
  const [isValidDescription, setIsInvalidDescription] = useState(false);
  const [isValidImageUrl, setIsInvalidImageUrl] = useState(false);
  const [isValidPosition, setIsInvalidPosition] = useState(false);
  const [isValidPrice, setIsInvalidPrice] = useState(false);
  const [isValidQty, setIsInvalidQty] = useState(false);

  async function getBranchSettings() {
    const response = await getSettings();

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    context.BranchMenu.setMenu(response.menu);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  function findProductByProductId(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  function onProductSelected(event: any, data: any) {
    var found = findProductByProductId(Number(data.value));

    if (found === undefined) {
      return;
    }

    setSearchValue(found.description);
    setProductId(found.id);
  }

  function validateMe() {
    let valid = true;

    //check for description
    if (description.length === 0) {
      setIsInvalidDescription(true);
      valid = false;
    } else {
      setIsInvalidDescription(false);
    }

    //check position
    const pos = parseFloat(position);
    if (position.length === 0 || isNaN(pos)) {
      setIsInvalidPosition(true);
      valid = false;
    } else {
      setIsInvalidPosition(false);
    }

    //price
    const p = parseFloat(price);
    if (price.length === 0 || isNaN(p)) {
      setIsInvalidPrice(true);
      valid = false;
    } else {
      setIsInvalidPrice(false);
    }

    //qty
    const q = parseFloat(qty);
    if (qty.length === 0 || isNaN(q)) {
      setIsInvalidQty(true);
      valid = false;
    } else {
      setIsInvalidQty(false);
    }

    return valid;
  }

  const productRow = (p: IExtra) => (
    <>
      <TableRow>
        <TableCell positive>{p.id}</TableCell>
        <TableCell positive>{p.optionId}</TableCell>
        <TableCell positive>{p.description}</TableCell>
        <TableCell positive>{p.image}</TableCell>
        <TableCell positive>{p.position}</TableCell>
        <TableCell positive>{p.price.toFixed(2)}</TableCell>
        <TableCell positive>{p.qty}</TableCell>
      </TableRow>
    </>
  );

  const extras = (): JSX.Element => (
    <>
      <h1>Extras</h1>
      <hr />
      <div>
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Id</TableHeaderCell>
              <TableHeaderCell>Option Id</TableHeaderCell>
              <TableHeaderCell>Description</TableHeaderCell>
              <TableHeaderCell>Image Url</TableHeaderCell>
              <TableHeaderCell>Position</TableHeaderCell>
              <TableHeaderCell>Price</TableHeaderCell>
              <TableHeaderCell>Qty</TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {context.BranchMenu.getMenu().extras.map((data) =>
              productRow(data)
            )}
          </TableBody>
        </Table>
      </div>
    </>
  );

  return <>{extras()}</>;
};
