import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Row } from "react-bootstrap";
import { Dropdown, Table } from "semantic-ui-react";
import ApplicationContext from "../../../context/ApplicationContext";
import { ISearchData } from "../../../types/ISearchData";
import { IExtra } from "../../../types/IExtra";
import { getSettings, storeExtra } from "../../../services/Service";

export const AddExtra: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [searchData, setSearchData] = useState<ISearchData[]>([]);
  const [searchValue, setSearchValue] = useState("Home");

  //selected values
  const [productId, setProductId] = useState(1);
  const [description, setDescription] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [position, setPosition] = useState("");
  const [price, setPrice] = useState("0.00");
  const [qty, setQty] = useState("");
  const [isCategory, setIsCategory] = useState(false);

  //validation
  const [isValidDescription, setIsInvalidDescription] = useState(false);
  // const [isValidImageUrl, setIsInvalidImageUrl] = useState(false);
  // const [isValidPosition, setIsInvalidPosition] = useState(false);
  const [isValidPrice, setIsInvalidPrice] = useState(false);
  //const [isValidQty, setIsInvalidQty] = useState(false);

  async function getBranchSettings() {
    const response = await getSettings();

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    let searchData: ISearchData[] = [];
    response.menu.products.forEach((p) => {
      const d: ISearchData = {
        productId: p.id,
        value: p.id.toString(),
        key: p.id.toString(),
        text: p.description,
        barcode: "",
      };
      searchData.push(d);
    });
    setSearchData(searchData);

    context.BranchMenu.setMenu(response.menu);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  function findProductByProductId(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  function onProductSelected(event: any, data: any) {
    var found = findProductByProductId(Number(data.value));

    if (found === undefined) {
      return;
    }

    setSearchValue(found.description);
    setProductId(found.id);
  }

  function validateMe() {
    let valid = true;

    //check for description
    if (description.length === 0) {
      setIsInvalidDescription(true);
      valid = false;
    } else {
      setIsInvalidDescription(false);
    }

    //check position
    // const pos = parseFloat(position);
    // if (position.length === 0 || isNaN(pos)) {
    //   setIsInvalidPosition(true);
    //   valid = false;
    // } else {
    //   setIsInvalidPosition(false);
    // }

    //price
    const p = parseFloat(price);
    if (price.length === 0 || isNaN(p)) {
      setIsInvalidPrice(true);
      valid = false;
    } else {
      setIsInvalidPrice(false);
    }

    // //qty
    // const q = parseFloat(qty);
    // if (qty.length === 0 || isNaN(q)) {
    //   setIsInvalidQty(true);
    //   valid = false;
    // } else {
    //   setIsInvalidQty(false);
    // }

    return valid;
  }

  function save() {
    const valid = validateMe();

    if (valid === false) {
      return;
    }

    const newExtra: IExtra = {
      id: 0,
      uuid: "",
      optionId: 0,
      description: description,
      image: imageURL,
      position: 0,
      price: Number(price),
      qty: 0,
    };

    const saved = storeExtra(newExtra);

    if (!saved) {
      //show error dialog
      //console.log("An error has occurred saving data");
      context.ApplicationError.addError("storing extra error");
      return;
    }
  }

  const searchProduct = () => (
    <>
      <Dropdown
        button
        //className="icon"
        fluid
        //labeled
        //icon="world"
        options={searchData}
        //search
        //clearable={true}
        value={searchValue}
        defaultSearchQuery={searchValue}
        searchQuery={searchValue}
        text={searchValue}
        //onSearchChange={handleOnSearchChange}
        onChange={onProductSelected}
        basic={true}
        onClick={onProductSelected}
        item={true}
        selectOnNavigation={true}
        selectOnBlur={false}
      />
    </>
  );

  const add = (): JSX.Element => (
    <>
      <div className="container">
        <h1>Add extra</h1>
        <hr />
        <Form>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Description
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                isInvalid={isValidDescription}
                required={true}
                type="text"
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Image URL
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Image URL"
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Price
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Price"
                isInvalid={isValidPrice}
                defaultValue={(0).toFixed(2)}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 10, offset: 2 }}>
              <Button variant="success" onClick={save}>
                Add extra
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    </>
  );

  return <>{add()}</>;
};
