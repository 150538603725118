import React, { useContext, useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import {
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "semantic-ui-react";
import ApplicationContext from "../../../context/ApplicationContext";
import { IInstruction } from "../../../types/IInstruction";
import { deleteInstruction, getSettings } from "../../../services/Service";
import { ISearchData } from "../../../types/ISearchData";
import { useNavigate } from "react-router-dom";
import { IProduct } from "../../../types/IProduct";

export interface IInstructionsProps {}

export const MultiInstructions: React.FC<IInstructionsProps> = (
  props: IInstructionsProps
): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [instructions, setInstructions] = useState<IInstruction[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [removeInstructionId, setRemoveInstructionId] = useState(-1);
  const [products, setProducts] = useState<IProduct[]>([]);

  const [searchValue, setSearchValue] = useState("Home");

  const navigate = useNavigate();

  async function getBranchSettings() {
    const response = await getSettings();

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    setInstructions(response.menu.instructions);
  }

  async function getBranchSettings1() {
    const response = await getSettings();

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    context.BranchMenu.setMenu(response.menu);

    const p = context.BranchMenu.getMenu().products.sort((a, b) =>
      a.position > b.position ? 1 : -1
    );

    setProducts(p);
  }

  useEffect(() => {
    getBranchSettings1();
  }, []);

  useEffect(() => {
    getBranchSettings();
  }, []);

  function edit(i: IInstruction) {
    navigate("/AddInstruction/" + i.id);
  }

  async function removeInstruction() {
    const ins: IInstruction = {
      id: removeInstructionId,
      uuid: "",
      productId: 0,
      description: "",
      image: "",
      position: 0,
      price: 0,
      qty: 0,
      required: false,
    };

    const response = await deleteInstruction(ins);

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    } else {
      getBranchSettings();
      setShowConfirm(false);
    }
  }

  const productRow = (p: IProduct) => (
    <>
      <TableRow>
        <TableCell positive>{p.id}</TableCell>
        <TableCell positive>{p.parentId}</TableCell>
        <TableCell positive>{p.description}</TableCell>
        <TableCell positive>{p.position}</TableCell>
        <TableCell positive>{p.price.toFixed(2)}</TableCell>
        <TableCell positive>{p.imageUrl}</TableCell>
        <TableCell positive>{p.qty}</TableCell>
        <TableCell positive>{p.isCategory ? "Yes" : "no"}</TableCell>
      </TableRow>
    </>
  );

  const categories = (): JSX.Element => {
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "200px",
          }}
        >
          <Form.Control
            //isInvalid={isValidDescription}
            required={true}
            type="text"
            placeholder=""
            //value={description}
            //onChange={(e) => setDescription(e.target.value)}
          />
          <div
            style={{
              width: "100%",
              height: "200px",
              backgroundColor: "lightcyan",
              overflowY: "scroll",
            }}
          >
            <Table celled>
              <TableHeader>
                <TableRow>
                  <TableHeaderCell>Id</TableHeaderCell>
                  <TableHeaderCell>ParentId</TableHeaderCell>
                  <TableHeaderCell>Description</TableHeaderCell>
                  <TableHeaderCell>Position</TableHeaderCell>
                  <TableHeaderCell>Price</TableHeaderCell>
                  <TableHeaderCell>Image URL</TableHeaderCell>
                  <TableHeaderCell>Qty</TableHeaderCell>
                  <TableHeaderCell>Is Category</TableHeaderCell>
                </TableRow>
              </TableHeader>
              <TableBody>{products.map((data) => productRow(data))}</TableBody>
            </Table>
          </div>
          categories
        </div>
        <div>
          <div
            style={{
              paddingLeft: 20,
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <div className="row">
              <div
                style={{
                  width: "50%",
                  overflowY: "scroll",
                  backgroundColor: "blue",
                }}
              ></div>

              <div
                style={{
                  width: "50%",
                  overflowY: "scroll",
                  backgroundColor: "red",
                }}
              ></div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const confirmView = (): JSX.Element => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        //style={{ width: "800px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <h5>Are you sure?</h5>
          </>
        </Modal.Body>
        <Modal.Footer className="modal-footer justify-content-between">
          <div className="mr-auto"></div>
          <div>
            <Button
              variant="primary"
              size="lg"
              onClick={() => setShowConfirm(false)}
            >
              Cancel
            </Button>{" "}
            <Button
              variant="danger"
              size="lg"
              onClick={() => removeInstruction()}
            >
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  function remove(id: number) {
    setShowConfirm(true);
    setRemoveInstructionId(id);
  }

  const row = (i: IInstruction) => (
    <>
      <TableRow>
        <TableCell positive>{i.id}</TableCell>
        <TableCell positive>{i.productId}</TableCell>
        <TableCell positive>{i.description}</TableCell>
        <TableCell positive>{i.image}</TableCell>
        <TableCell positive>{i.position}</TableCell>
        <TableCell positive>{i.price.toFixed(2)}</TableCell>
        <TableCell positive>{i.required ? "Yes" : "No"}</TableCell>
        <TableCell positive>
          <Button variant="outline-primary" onClick={() => edit(i)}>
            Edit
          </Button>
        </TableCell>
        <TableCell positive>
          <Button variant="outline-primary" onClick={() => remove(i.id)}>
            X
          </Button>
        </TableCell>
      </TableRow>
    </>
  );

  const variations = (): JSX.Element => (
    <>
      <h1>Instructions</h1>
      <hr />
      <div>
        <Table celled>
          <TableHeader>
            <TableRow>
              <TableHeaderCell>Id</TableHeaderCell>
              <TableHeaderCell>Product Id</TableHeaderCell>
              <TableHeaderCell>Description</TableHeaderCell>
              <TableHeaderCell>Image Url</TableHeaderCell>
              <TableHeaderCell>Position</TableHeaderCell>
              <TableHeaderCell>Price</TableHeaderCell>
              <TableHeaderCell>Required</TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
              <TableHeaderCell></TableHeaderCell>
            </TableRow>
          </TableHeader>
          <TableBody>{instructions.map((data) => row(data))}</TableBody>
        </Table>
      </div>
    </>
  );

  return (
    <>
      <>{categories()}</>
      <>{variations()}</>
      <>{confirmView()}</>
    </>
  );
};
