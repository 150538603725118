import { ICustomer } from "../../types/ICustomer";
import { ICustomerData } from "./ICustomerData";

let customer: ICustomer = {
  id: 0,
  name: "",
  addressLines: [],
  telephone: "",
  postcode: "",
  endComment: "",
};

export class CustomerData implements ICustomerData {
  public getCustomer(): ICustomer {
    return customer;
  }
  public setCustomer(c: ICustomer) {
    let deepCopy: ICustomer = JSON.parse(JSON.stringify(c));
    customer = deepCopy;
  }
}
