import React, { useContext, useEffect, useState } from "react";

import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { Dropdown, Table } from "semantic-ui-react";
import ApplicationContext from "../../../context/ApplicationContext";
import { getSettings, storeInstruction } from "../../../services/Service";
import { ISearchData } from "../../../types/ISearchData";
import { IInstruction } from "../../../types/IInstruction";
import { useNavigate, useParams } from "react-router-dom";
import { Instructions } from "./Instructions";
import { GenerateGUID } from "../../../helpers/Helpers";

export const AddInstructions: React.FC = (): JSX.Element => {
  const context = useContext(ApplicationContext);

  const [searchData, setSearchData] = useState<ISearchData[]>([]);
  const [searchValue, setSearchValue] = useState("Home");

  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState("");
  const { id } = useParams();

  //selected values
  const [productId, setProductId] = useState(1);
  const [description, setDescription] = useState("");
  const [imageURL, setImageUrl] = useState("");
  const [position, setPosition] = useState("");
  const [price, setPrice] = useState("0.00");
  const [qty, setQty] = useState("");

  const navigate = useNavigate();

  //validation
  const [isValidDescription, setIsInvalidDescription] = useState(false);
  // const [isValidImageUrl, setIsInvalidImageUrl] = useState(false);
  const [isValidPosition, setIsInvalidPosition] = useState(false);
  const [isValidPrice, setIsInvalidPrice] = useState(false);
  const [isValidQty, setIsInvalidQty] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);

  const [required, setIsRequired] = useState(false);

  async function getBranchSettings() {
    const response = await getSettings();

    if (!response) {
      context.ApplicationError.setHasError(true);
      return;
    }

    let searchData: ISearchData[] = [];
    response.menu.products.forEach((p) => {
      const d: ISearchData = {
        productId: p.id,
        value: p.id.toString(),
        key: p.id.toString(),
        text: p.description,
        barcode: "",
      };
      searchData.push(d);
    });
    setSearchData(searchData);

    context.BranchMenu.setMenu(response.menu);
  }

  useEffect(() => {
    getBranchSettings();
  }, []);

  useEffect(() => {
    if (id === undefined) {
      setEditMode(false);
    } else {
      setEditMode(true);
      setEditId(id);

      const instruction = findInstructionById(Number(id));

      if (instruction === undefined) {
        return;
      }

      const parentProduct = findProductByProductId(instruction.productId);

      if (parentProduct === undefined) {
        return;
      }

      setSearchValue(parentProduct.description);
      setProductId(parentProduct.id);
      setDescription(instruction.description);
      setImageUrl(instruction.image);
      setPosition(instruction.position.toString());
      setPrice(instruction.price.toFixed(2));
      setQty(instruction.qty.toString());
      setIsRequired(instruction.required);
    }
  }, []);

  function findInstructionById(id: number) {
    return context.BranchMenu.getMenu().instructions.find((element) => {
      return element.id === id;
    });
  }

  function findProductByProductId(id: number) {
    return context.BranchMenu.getMenu().products.find((element) => {
      return element.id === id;
    });
  }

  function onProductSelected(event: any, data: any) {
    var found = findProductByProductId(Number(data.value));

    if (found === undefined) {
      return;
    }

    setSearchValue(found.description);
    setProductId(found.id);
  }

  function validateMe() {
    let valid = true;

    //check for description
    if (description.length === 0) {
      setIsInvalidDescription(true);
      valid = false;
    } else {
      setIsInvalidDescription(false);
    }

    //check position
    const pos = parseFloat(position);
    if (position.length === 0 || isNaN(pos)) {
      setIsInvalidPosition(true);
      valid = false;
    } else {
      setIsInvalidPosition(false);
    }

    //price
    const p = parseFloat(price);
    if (price.length === 0 || isNaN(p)) {
      setIsInvalidPrice(true);
      valid = false;
    } else {
      setIsInvalidPrice(false);
    }

    //qty
    // const q = parseFloat(qty);
    // if (qty.length === 0 || isNaN(q)) {
    //   setIsInvalidQty(true);
    //   valid = false;
    // } else {
    //   setIsInvalidQty(false);
    // }

    return valid;
  }

  function save() {
    const valid = validateMe();

    if (valid === false) {
      return;
    }

    const data: IInstruction = {
      id: editMode ? Number(editId) : -1,
      uuid: GenerateGUID(),
      productId: productId,
      description: description,
      image: imageURL,
      position: Number(position),
      price: Number(price),
      qty: 0,
      required: required,
    };

    const saved = storeInstruction(data);

    if (!saved) {
      context.ApplicationError.addError("storing instruction error");
      return;
    } else {
      setShowConfirm(true);
    }
  }
  function showAllInstructions() {
    setShowConfirm(false);
    navigate("/Instructions");
  }

  const confirmView = (): JSX.Element => {
    return (
      <Modal
        show={showConfirm}
        onHide={() => setShowConfirm(false)}
        //style={{ width: "800px" }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Saved</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <h5>Item is saved</h5>
          </>
        </Modal.Body>
        <Modal.Footer className="modal-footer justify-content-between">
          <div className="mr-auto"></div>
          <div>
            <Button
              variant="success"
              size="lg"
              onClick={() => showAllInstructions()}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };

  const searchProduct = () => (
    <>
      <Dropdown
        button
        //className="icon"
        fluid
        //labeled
        //icon="world"
        options={searchData}
        //search
        //clearable={true}
        value={searchValue}
        defaultSearchQuery={searchValue}
        searchQuery={searchValue}
        text={searchValue}
        //onSearchChange={handleOnSearchChange}
        onChange={onProductSelected}
        basic={true}
        onClick={onProductSelected}
        item={true}
        selectOnNavigation={true}
        selectOnBlur={false}
      />
    </>
  );

  function toggleIsCategory() {
    if (required === true) {
      setIsRequired(false);
    } else {
      setIsRequired(true);
    }
  }

  const add = (): JSX.Element => (
    <>
      <div className="container">
        <h1>{editMode ? <>Edit Instructions</> : <>Add Instructions</>}</h1>
        <hr />
        <Form>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={2}>
              Product
            </Form.Label>
            <Col sm={10}>{searchProduct()}</Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Description
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                isInvalid={isValidDescription}
                required={true}
                value={description}
                type="text"
                placeholder="Description"
                onChange={(e) => setDescription(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Image URL
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                value={imageURL}
                placeholder="Image URL"
                onChange={(e) => setImageUrl(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Price
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Price"
                value={price}
                isInvalid={isValidPrice}
                defaultValue={(0).toFixed(2)}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Col>
          </Form.Group>
          <Form.Group
            as={Row}
            className="mb-3"
            controlId="formHorizontalPassword"
          >
            <Form.Label column sm={2}>
              Position
            </Form.Label>
            <Col sm={10}>
              <Form.Control
                type="text"
                placeholder="Position"
                isInvalid={isValidPosition}
                value={position}
                onChange={(e) => setPosition(e.target.value)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3" controlId="formHorizontalCheck">
            <Col sm={{ span: 10, offset: 2 }}>
              <Form.Check
                label="Is required"
                checked={required}
                onClick={(e) => toggleIsCategory()}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-3">
            <Col sm={{ span: 10, offset: 2 }}>
              <Button variant="success" onClick={save}>
                {editMode ? <>Edit instruction</> : <>Add instruction</>}
              </Button>
            </Col>
          </Form.Group>
        </Form>
      </div>
    </>
  );

  return (
    <>
      <>{add()}</>
      <>{confirmView()}</>
    </>
  );
};
